import React from 'react'
import { TablePaginationConfig } from 'antd'
import { useStoreViewContext } from './../../context/storeViewContext/StoreViewContext'
import { EnumConfigType } from '../../types/enums'
import { ISpecialOpTimeItem } from '../../api/apiModel/specialOpTime'
import moment from 'moment'
import { IPageInfo } from '../../types/interface'
import Table from '../tools/Table'

interface ISpecialOpTimeTableProps {
  list: ISpecialOpTimeItem[]
  total: number
  pageInfo: IPageInfo
  onPageChange: (props: { size: number; page: number }) => void
  onClickByEdit: (data: ISpecialOpTimeItem) => void
}

const SpecialOpTimeTable = ({
  list,
  total,
  pageInfo,
  onPageChange,
  onClickByEdit,
  ...props
}: ISpecialOpTimeTableProps) => {
  const { configMap } = useStoreViewContext()

  const columns = [
    {
      title: 'CN Code',
      dataIndex: 'Store_Code',
      fixed: true,
      width: 100,
      render: (text, item) => (
        <a
          onClick={(e) => {
            onClickByEdit(item)
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Store Name',
      dataIndex: 'Store_Name',
      fixed: true,
    },
    {
      title: 'Region',
      dataIndex: 'Region',
      render: (id) => (
        <span>
          {
            configMap
              .get(EnumConfigType.Store_Region)
              ?.find((item) => item.Id === id)?.Value
          }
        </span>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'Date',
    },
    {
      title: 'Normal Shop Hours',
      dataIndex: 'Normal_Business_Time',
    },
    {
      title: 'Actual Shop Hours',
      dataIndex: 'Actual_Business_Time',
    },
    {
      title: 'Time Gap',
      dataIndex: 'Time_Difference',
    },
    {
      title: 'Reason',
      dataIndex: 'Reason',
    },
  ]
  const data = list.map((item) => ({
    ...item,
    Date: moment.unix(item.Date).format('YYYY/MM/DD'),
    Normal_Business_Time: `${moment
      .unix(item.Normal_Business_Start_Time)
      .format('HH:mm')}-${moment
      .unix(item.Normal_Business_End_Time)
      .format('HH:mm')}`,
    Actual_Business_Time: `${moment
      .unix(item.Actual_Business_Start_Time)
      .format('HH:mm')}-${moment
      .unix(item.Actual_Business_End_Time)
      .format('HH:mm')}`,
    key: item.Id,
  }))

  const onChangeByTable = (pagination: TablePaginationConfig) => {
    const { pageSize: size, current: page } = pagination
    if (pageInfo.size != size || pageInfo.page != page) {
      onPageChange({ size, page })
    }
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        onChange={onChangeByTable}
        scroll={{ x: 1200 }}
        pagination={{
          total,
          showTotal:(total) => `Total ${total} items`,
          current: pageInfo.page,
          pageSize: pageInfo.size,
          showSizeChanger: true,
        }}
      />
    </>
  )
}

export default SpecialOpTimeTable
