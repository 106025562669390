import React, { useEffect, useState } from 'react'
import SpecialOpTimeDetailContent from './Detail'
import SpecialOpTimeListContent from './List'
import { ISpecialOpTimeItem } from '../../api/apiModel/specialOpTime'
import apiCollection from '../../api'
import { EnumMenuTypes, EnumOperateType } from '../../types/enums'
import { IPageInfo } from '../../types/interface'
import Menu from '../tools/Menu'
import { IOwnStoreItem } from '../../api/apiModel/ownStore'

export interface IOperateCallbackParams {
  item?: ISpecialOpTimeItem
  filter?: object
  pageInfo?: IPageInfo
}

const SpecialOpTimeContent = () => {
  const [isShowDetail, displayDetailPage] = useState<boolean>(false)
  const [specialOpTimeList, setSpecialOpTimeList] = useState<
    ISpecialOpTimeItem[]
  >([])
  const [editData, setEditData] = useState<ISpecialOpTimeItem>()
  const [filterData, setFilterData] = useState<object>({})
  const [pageInfo, setPageInfo] = useState<IPageInfo>({ page: 1, size: 10 })
  const [isLoading, showLoading] = useState<boolean>(false)
  const [specialOpTimeTotal, setSpecialOpTimeTotal] = useState<number>(0)
  const [storeList, setStoreList] = useState<IOwnStoreItem[]>([])
  const [storeCodeList, setStoreCodeList] = useState<{ value: string }[]>([])

  const getSpecialOpTimeList = async () => {
    showLoading(true)
    const result = await apiCollection.getSpecialOpTimeList({
      ...filterData,
      ...pageInfo,
    })

    if (result.success) {
      const { data: list, total } = result.data
      setSpecialOpTimeList(list)
      setSpecialOpTimeTotal(total)
    }
    showLoading(false)
  }

  const getAllStoreList = async () => {
    const result = await apiCollection.getOwnStoreList({})
    const storeList = result.success ? result?.data?.data : []
    const storeCodeList =
      storeList?.map((item) => ({ value: item?.Store_Code })) ?? []
    setStoreList(storeList)
    setStoreCodeList(storeCodeList)
  }

  const operateCallback = (
    type: EnumOperateType,
    data?: IOperateCallbackParams
  ) => {
    switch (type) {
      case EnumOperateType.Update:
        displayDetailPage(true)
        setEditData(data.item)
        break

      case EnumOperateType.Add:
        displayDetailPage(true)
        break

      case EnumOperateType.Cancel:
        setEditData(null)
        displayDetailPage(false)
        break

      case EnumOperateType.Submit:
        setEditData(null)
        displayDetailPage(false)
        getSpecialOpTimeList()
        break

      case EnumOperateType.Filter:
        setFilterData(data.filter)
        setPageInfo((current) => ({ ...current, page: 1 }))
        break

      case EnumOperateType.ChangePage:
        setPageInfo((current) => ({ ...current, ...data.pageInfo }))
        break
    }
  }

  useEffect(() => {
    getAllStoreList()
  }, [])

  useEffect(() => {
    getSpecialOpTimeList()
  }, [pageInfo])

  return (
    <>
      <Menu
        current={EnumMenuTypes.SpecialOpTime}
        refreshPage={() => {
          operateCallback(EnumOperateType.Cancel)
        }}
      />
      {isShowDetail && (
        <SpecialOpTimeDetailContent
          isEdit={!!editData?.Id}
          editData={editData}
          storeList={storeList}
          operateCallback={operateCallback}
        />
      )}
      {!isShowDetail && (
        <SpecialOpTimeListContent
          list={specialOpTimeList}
          total={specialOpTimeTotal}
          filterData={filterData}
          isLoading={isLoading}
          pageInfo={pageInfo}
          storeCodeList={storeCodeList}
          operateCallback={operateCallback}
        />
      )}
    </>
  )
}

export default SpecialOpTimeContent
