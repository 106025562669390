import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { filterFields } from './FormField'
import FormContent from '../form/Form'
import { useStoreViewContext } from './../../context/storeViewContext/StoreViewContext'
import {
  EnumConfigType,
  EnumExcelType,
  EnumFormFieldType,
} from '../../types/enums'
import { IFromMapItem } from '../../types/interface'
import Download from './../icon/Download'
import IconContent from './../icon/IconContent'
import { PlusOutlined } from '@ant-design/icons'
import apiCollection from '../../api'

const SpecialOpTimeFilterContainer = styled.div`
  padding-bottom: 4.8rem;
  position: relative;
`

interface ISpecialOpTimeFilterProps {
  filterData: object
  storeCodeList: { value: string; label?: string }[]
  changeFilter: (filterData: object) => void
  addSpecialOpTime: () => void
}

const SpecialOpTimeFilterContent = ({
  filterData,
  storeCodeList,
  changeFilter,
  addSpecialOpTime,
}: ISpecialOpTimeFilterProps) => {
  const { configMap } = useStoreViewContext()

  const optionsMap = useMemo(() => {
    return {
      regionList: configMap.get(EnumConfigType.Store_Region),
      storeCodeList,
    }
  }, [storeCodeList, configMap])

  const initFilterData = useMemo(() => {
    const initData = { ...filterData }
    filterFields.forEach((item) => {
      switch (item.type) {
        case EnumFormFieldType.DateRangePicker:
          if (initData[item.name]?.includes('-')) {
            initData[item.name] = initData[item.name]
              .split('-')
              .map((timeItem) => timeItem * 1000)
          }
          break
      }
    })
    return initData
  }, [filterData, filterFields])

  const formMap: IFromMapItem[] = [
    {
      panelName: 'Special OP Time Filter',
      fieldsList: filterFields,
      showSubmitButton: true,
      submitButtonLabel: '搜索',
      cancelButtonLabel: '重置',
      buttonPosition: 'right',
      showCancelButton: true,
      customFormButton: [
        <Download fileUrl={`report/${EnumExcelType.StoreReport}`} />,
        <IconContent
          icon={PlusOutlined}
          tips={'新增OpTime'}
          clickCallback={addSpecialOpTime}
        />,
      ],
    },
  ]

  const resetFilter = () => {
    changeFilter({})
  }

  const submitFilter = (form) => {
    const data = { ...form }

    filterFields.forEach((item) => {
      switch (item.type) {
        case EnumFormFieldType.DateRangePicker:
          data[item.name] = data[item.name]?.join('-')
          break
      }
    })

    changeFilter(data)
  }

  return (
    <SpecialOpTimeFilterContainer>
      <FormContent
        isEdit={true}
        initData={initFilterData}
        hiddenPanel={true}
        disableCatch={true}
        formMap={formMap}
        optionsMap={optionsMap}
        expandIcon={() => ''}
        submitCallback={submitFilter}
        cancelCallback={resetFilter}
      />
    </SpecialOpTimeFilterContainer>
  )
}

export default SpecialOpTimeFilterContent
