import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { fieldsList } from './FormField'
import FormContent from '../form/Form'
import apiCollection from '../../api'
import { EnumConfigType, EnumOperateType } from '../../types/enums'
import { useStoreViewContext } from './../../context/storeViewContext/StoreViewContext'
import { ISpecialOpTimeItem } from '../../api/apiModel/specialOpTime'
import { IFromMapItem } from '../../types/interface'
import { message } from 'antd'
import { IOwnStoreItem } from '../../api/apiModel/ownStore'
import moment from 'moment'

interface SpecialOpTimeDetailProps {
  isEdit?: boolean
  editData?: ISpecialOpTimeItem
  storeList: IOwnStoreItem[]
  operateCallback: (type: EnumOperateType) => void
}

const SpecialOpTimeDetailContent = ({
  isEdit,
  editData,
  storeList,
  operateCallback,
}: SpecialOpTimeDetailProps) => {
  const { configMap } = useStoreViewContext()

  const [initData, setInitData] = useState(null)

  const optionsMap = useMemo(() => {
    const formationStoreList =
      storeList?.map((item) => ({
        id: item?.Store_Code,
        value: `${item.Store_Code} -- ${item.Name_CN}`,
      })) ?? []
    return {
      regionList: configMap.get(EnumConfigType.Store_Region),
      storeList: formationStoreList,
    }
  }, [storeList, configMap])

  const storeDataMap = useMemo(() => {
    const _map: Map<string, string> = new Map()
    storeList?.forEach((item) => {
      _map.set(item?.Store_Code, item?.Name_CN)
    })
    return _map
  }, [storeList])

  const formMap = useMemo((): IFromMapItem[] => {
    const fields =
      fieldsList?.map((item) =>
        ['Region', 'Date_Range', 'Store_Code'].includes(item.name)
          ? { ...item, extra: { ...item.extra, disabled: isEdit } }
          : item
      ) ?? []
    return [
      {
        panelName: 'Special OP Time',
        fieldsList: fields,
        showSubmitButton: true,
        showCancelButton: true,
      },
    ]
  }, [fieldsList, isEdit])

  const submitCallback = async (formData) => {
    const {
      Normal_Business_Time,
      Actual_Business_Time,
      Date_Range,
      ...defaultSpecialOpTimeData
    } = formData

    const params: ISpecialOpTimeItem = {
      ...defaultSpecialOpTimeData,
      Normal_Business_Start_Time: formData?.Normal_Business_Time?.[0],
      Normal_Business_End_Time: formData?.Normal_Business_Time?.[1],
      Actual_Business_Start_Time: formData?.Actual_Business_Start_Time,
      Actual_Business_End_Time: formData?.Actual_Business_End_Time,
      Store_Name: storeDataMap?.get(formData.Store_Code),
    }

    const result = isEdit
      ? await apiCollection.updateSpecialOpTimeDetailInfo(editData?.Id, params)
      : await createSpecialOpTimeDetailInfo(params, Date_Range)
    if (result.success) {
      operateCallback(EnumOperateType.Submit)
    } else {
      message.error(result.message)
      throw Error(result.message)
    }
  }

  const createSpecialOpTimeDetailInfo = async (params, Date_Range) => {
    if (!Date_Range || parseInt(Date_Range) < 2) {
      return apiCollection.createSpecialOpTimeDetailInfo(params)
    }

    for (let i = 0; i < parseInt(Date_Range); i++) {
      const result = await apiCollection.createSpecialOpTimeDetailInfo({
        ...params,
        Date: params.Date + i * 60 * 60 * 24,
      })

      if (!result.success) {
        if (i === 0) {
          return result
        }
        return {
          success: false,
          message: `${result.message}, Date start from ${moment
            .unix(params.Date + i * 60 * 60 * 24)
            .format(
              'YYYY/MM/DD'
            )} created failed, please edit the Date and Date Range to create the failed ones`,
        }
      }
    }

    return {
      success: true,
      message: 'success',
    }
  }

  const cancelCallback = () => {
    operateCallback(EnumOperateType.Cancel)
  }

  const initFormatData = () => {
    if (!editData || !Object.keys(editData).length) return

    const {
      Normal_Business_Start_Time,
      Normal_Business_End_Time,
      Actual_Business_Start_Time,
      Actual_Business_End_Time,
      ...defaultSpecialOpTimeData
    } = editData

    setInitData({
      ...defaultSpecialOpTimeData,
      Normal_Business_Time: [
        Normal_Business_Start_Time * 1000,
        Normal_Business_End_Time * 1000,
      ],
      Actual_Business_Start_Time: Actual_Business_Start_Time * 1000,
      Actual_Business_End_Time: Actual_Business_End_Time * 1000,
    })
  }

  useEffect(() => {
    initFormatData()
  }, [editData])

  return (
    <>
      <FormContent
        formMap={formMap}
        optionsMap={optionsMap}
        isEdit={isEdit}
        initData={initData}
        formKeyName={initData?.Id?.toString()}
        submitCallback={submitCallback}
        cancelCallback={cancelCallback}
      />
    </>
  )
}

export default SpecialOpTimeDetailContent
