import { EnumFormFieldType } from '../../types/enums'
import { IFormField } from '../../types/interface'

export const fieldsList: IFormField[] = [
  {
    name: 'Region',
    label: 'Region',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'regionList',
    col: 8,
  },
  {
    name: 'Date',
    label: 'Date',
    require: true,
    type: EnumFormFieldType.DatePicker,
    col: 8,
  },
  {
    name: 'Date_Range',
    label: 'Date Range',
    require: false,
    type: EnumFormFieldType.Input,
    rules: [
      {
        pattern: /^([2-9]|[1-2]\d|3[0-1])$/,
        message: '只能输入2到31之间的正整数',
      },
    ],
    col: 8,
  },
  {
    name: 'Store_Code',
    label: 'Store',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'storeList',
  },
  {
    name: 'Normal_Business_Time',
    label: 'Normal Shop Hours',
    require: true,
    type: EnumFormFieldType.TimeRangePicker,
    col: 8,
    onChange: ({ value, form }) => {
      const actualBusinessStartTime = form.getFieldValue(
        'Actual_Business_Start_Time'
      )
      const actualBusinessEndTime = form.getFieldValue(
        'Actual_Business_End_Time'
      )
      if (actualBusinessStartTime && actualBusinessEndTime) {
        const actualTimeRange =
          ((actualBusinessEndTime.unix() - actualBusinessStartTime.unix()) /
            60 /
            60 +
            24) %
          24
        const normalTimeRange = (value[1].unix() - value[0].unix()) / 60 / 60
        form.setFields([
          {
            name: 'Time_Difference',
            value: (actualTimeRange - normalTimeRange).toFixed(1),
          },
        ])
      }
    },
    extra: { format: 'HH:mm', minuteStep: 15 },
  },
  {
    name: 'Actual_Business_Start_Time',
    label: 'Actual Start Hours',
    require: true,
    type: EnumFormFieldType.TimePicker,
    col: 8,
    onChange: ({ value, form }) => {
      const normalBusinessTime = form.getFieldValue('Normal_Business_Time')
      const actualBusinessEndTime = form.getFieldValue(
        'Actual_Business_End_Time'
      )
      if (normalBusinessTime && actualBusinessEndTime) {
        const normalTimeRange =
          (normalBusinessTime[1].unix() - normalBusinessTime[0].unix()) /
          60 /
          60
        const actualTimeRange =
          ((actualBusinessEndTime.unix() - value.unix()) / 60 / 60 + 24) % 24
        form.setFields([
          {
            name: 'Time_Difference',
            value: (actualTimeRange - normalTimeRange).toFixed(1),
          },
        ])
      }
    },
    extra: { format: 'HH:mm', minuteStep: 15 },
  },
  {
    name: 'Actual_Business_End_Time',
    label: 'Actual End Hours',
    require: true,
    type: EnumFormFieldType.TimePicker,
    col: 8,
    onChange: ({ value, form }) => {
      const normalBusinessTime = form.getFieldValue('Normal_Business_Time')
      const actualBusinessStartTime = form.getFieldValue(
        'Actual_Business_Start_Time'
      )
      if (normalBusinessTime && actualBusinessStartTime) {
        const normalTimeRange =
          (normalBusinessTime[1].unix() - normalBusinessTime[0].unix()) /
          60 /
          60
        const actualTimeRange =
          ((value.unix() - actualBusinessStartTime.unix()) / 60 / 60 + 24) % 24
        form.setFields([
          {
            name: 'Time_Difference',
            value: (actualTimeRange - normalTimeRange).toFixed(1),
          },
        ])
      }
    },
    extra: { format: 'HH:mm', minuteStep: 15 },
  },
  {
    name: 'Time_Difference',
    label: 'Time Gap',
    require: true,
    type: EnumFormFieldType.Input,
    extra: { disabled: 'disabled' },
  },
  {
    name: 'Reason',
    label: 'Reason',
    require: true,
    type: EnumFormFieldType.Input,
    rules: [
      {
        validator: (_, value) => {
          if (!value) return Promise.resolve('')
          if (!value.trim()) return Promise.reject(new Error(`原因 为必填项！`))
          return Promise.resolve()
        },
      },
    ],
  },
  {
    name: 'Remark',
    label: 'Remark',
    require: false,
    type: EnumFormFieldType.Input,
  },
]

export const filterFields: IFormField[] = [
  {
    name: 'Region',
    label: 'Region',
    type: EnumFormFieldType.Select,
    optionsName: 'regionList',
    col: 12,
  },
  {
    name: 'Date',
    label: 'Date Range',
    type: EnumFormFieldType.DateRangePicker,
    col: 12,
  },
  {
    name: 'Store_Code',
    label: 'CN Code',
    type: EnumFormFieldType.AutoComplete,
    optionsName: 'storeCodeList',
    col: 12,
    rules: [
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '只能输入数字或字母',
      },
    ],
  },
  {
    name: 'Store_Name',
    label: 'Store Name',
    type: EnumFormFieldType.Input,
    col: 12,
  },
  {
    name: 'Reason',
    label: 'Reason',
    type: EnumFormFieldType.Input,
  },
]
