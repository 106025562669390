import React from 'react'

import { AuthorizationProvider } from '../context/authorizationContext/AuthorizationContext'
import { StoreViewProvider } from '../context/storeViewContext/StoreViewContext'
import Header from '../component/tools/Header'
import BodyContainer from '../component/layout/BodyContainer'
import SpecialOpTime from '../component/specialOpTime/SpecialOpTime'

import { EnumMenuTypes } from '../types/enums'

const SpecialOpTimePageContainer = () => {
  return (
    <AuthorizationProvider>
      <StoreViewProvider>
        <Header title={EnumMenuTypes.SpecialOpTime} />
        <BodyContainer>
          <SpecialOpTime />
        </BodyContainer>
      </StoreViewProvider>
    </AuthorizationProvider>
  )
}

export default SpecialOpTimePageContainer
